.faq_main_div {
  background-image: url("../Images/login.jpg");
  height: 250px;
}

.faq_heading {
  color: #fff;
  text-align: center;
  padding-top: 140px;
  font-size: 35px;
  font-weight: bold;
}

.faq_div {
  background-color: #fff;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 29vh;
}

.accordion-button {
  font-weight: bold;
  color: #000000;
  font-size: 15px;
  display: flex;
  align-items: baseline;
}

.accordion_main {
  margin-top: 10px;
}

.accordion-button::after {
  content: "+";
  background-image: none;
  font-weight: bold;
  color: #000000;
  font-size: 19px;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: none;
  content: "-";
  font-weight: bold;
  color: #000000;
  font-size: 19px;
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(251, 251, 251, 0.25);
}

.accordion-header {
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 991px) {
  .faq_heading {
    padding-top: 185px;
    font-size: 23px;
  }
}
