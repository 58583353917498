.advert_main_div {
  background-image: url("../Images/login.jpg");
  height: 250px;
}

.advert_heading {
  color: #fff;
  text-align: center;
  padding-top: 140px;
  font-size: 35px;
  font-weight: bold;
}

.advert_main {
  background-color: #fff;
  width: 100%;
  padding: 50px 0px;
}

.reg_heading {
  font-weight: 500;
  font-size: 17px;
  color: #444;
}

.register_label {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #444;
}

.currency_label{
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #444;
}

.register_input {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.car_main_div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.car_div {
  width: 50%;
}

.car_input {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.registerinput {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.register_input_description {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
}

.place_ad_heading {
  padding: 0px;
  font-size: 30px;
  margin-bottom: 30px;
}

.advert_info {
  display: flex;
  gap: 20px;
}

.advert_form {
  width: 75%;
  padding: 30px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.advert_suggestion {
  width: 25%;
}

.advert_div {
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.price_div {
  display: flex;
}

.location_ad_div {
  display: flex;
  align-items: baseline;
  justify-content: end;
  gap: 500px;
}

.location {
  margin-top: 20px;
}

.town_name {
  display: flex;
  margin-top: 48px;
}

.county_label {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #444;
}

.submit_place_ad {
  display: flex;
  justify-content: end;
  padding-top: 20px;
}

.info_place_add {
  text-align: center;
}

.place_icon {
  color: #d9242c;
  font-size: 50px;
}

.place_ad_heading_info {
  font-size: 30px;
}

.checkbox_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 20px 0px;
}

.checkbox {
  display: flex;
  gap: 10px;
}

.place_ad_form_div {
  display: flex; 
  width: 100%;
  gap: 10px;
}

.ad_left_portion, .ad_right_portion {
  width: 50%;
}

@media screen and (max-width: 991px) {
  .advert_info {
    display: block;
  }
  .advert_form {
    width: auto;
    margin-bottom: 35px;
  }
  .advert_suggestion {
    width: auto;
  }
  .advert_heading {
    padding-top: 185px;
    font-size: 23px;
  }
}

@media screen and (max-width: 767px) {
  .checkbox_div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 394px) {
  .checkbox_div {
    grid-template-columns: repeat(1, 1fr);
  }
  .register_label {
    font-size: 13px;
  }
  .town_name {
    margin-top: 50px;
  }
}
