.forgetpswd_main {
  padding-top: 250px;
  padding-bottom: 220px;
}

.login_verify_form {
  width: 75%;
  margin: auto;
  padding: 30px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.loginverify_heading {
  font-weight: 700;
  color: #c2c2c2;
  text-align: center;
  font-size: 30px;
  width: 98%;
}