.inactive_div {
  background-color: #f5f5f5;
  min-height: 57vh;
}

.box_inactive_main_div {
  padding-top: 200px;
  padding-bottom: 100px;
}

.box_inactive {
  width: 50%;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 25px 10px;
  text-align: center;
  border: 1px solid #d9d4d4;
}

.access_ad {
  color: #d9242c;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .box_inactive {
    width: 100%;
    margin: auto;
  }
}
