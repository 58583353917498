.product-page-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 25px;
}

.main-image-wrapper {
  background-color: #f3f3f3;
}

.main-image-container {
  width: 80%;
  height: 430px;
  margin: auto;
  position: relative;
}

.main-image {
  border-radius: 5px;
  width: 100%;
  height: 430px;
  padding: 55px;
  object-fit: cover;
  margin: auto;
  cursor: pointer;
}

.price1 {
  position: absolute;
  top: 20px;
  right: 0;
  background: #d9242c;
  padding: 0px 25px;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}

.thumbnail-container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}

.thumbnail-wrapper {
  position: relative;
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
}

.thumbnail-lightbox-wrapper {
  position: relative;
  cursor: pointer;
  padding: 0 20px;
}

.thumbnail-image {
  border-radius: 5px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  pointer-events: none; 
  display: block; 
}

.thumbnail-wrapper:hover .thumbnail-overlay {
  background-color: rgba(255, 255, 255, 0);
}

.selected .thumbnail-overlay {
  display: none; 
}

.slick-prev,
.slick-next {
  font-size: 24px;
  color: #000;
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: rgba(217, 36, 44, 0.5) !important;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(217, 36, 44, 0.8);
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Lightbox */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
}

/* .lightbox-main-image {
  width: 50%;
  max-height: 70vh;
  object-fit: contain;
} */

.lightbox-close {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  color: black;
  font-size: 2rem;
  cursor: pointer;
}

.main-lightbox-image-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 30px !important;
}

.thumbnail-lightbox-image {
  border-radius: 5px;
  /* width: 80%; */
  height: 80px;
  object-fit: cover;
}


@media screen and (max-width: 685px) {
  .main-image-container {
    height: 365px;
  }
  .main-image {
    height: 360px;
  }
  .price1 {
    top: 15px;
  }
}

@media screen and (max-width: 540px) {
  .main-image-container {
    height: 240px;
  }
  .price1 {
    top: 10px;
  }
  .thumbnail-container {
    width: 100%;
  }
  .thumbnail-wrapper, .thumbnail-lightbox-wrapper {
    padding: 0px 4px;
  }
  .main-image {
    height: 230px;
    padding: 0px;
  }
  .thumbnail-image {
    width: 60px;
    height: 60px;
  }
  .thumbnail-lightbox-image {
    width: 80%;
    height: 60px;
  }
}
