.container {
  max-width: 1600px;
}

.home_main_div {
  background-color: #f5f5f5;
  padding-bottom: 45px;
}

.home_category_section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../Images/car.jpg);
  background-size: cover;
  background-position: center;
}

.category_section {
  padding-top: 187px;
  padding-bottom: 75px;
}

.category_box_div {
  border-radius: 5px;
  padding: 9px;
  background-color: #eee;
}

.category_box_div:hover {
  background-color: #ffbc00;
}

.grid_section {
  padding-top: 40px;
}

.featured_heading {
  text-align: center;
}

.featured_line {
  margin: auto auto 25px auto;
  width: 100px;
  border: 1px solid #d9242c;
}

.product_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-bottom: 20px;
  gap: 20px;
}

.category_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 45px;
  gap: 10px;
}

.category_grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 15px;
}

.category_div {
  text-align: center;
  box-sizing: border-box;
  height: 100px;
}

.category_icon {
  color: white;
  border-radius: 5px;
}

.category_icon:hover {
  border: 1px solid #060606;
  cursor: pointer;
}

.category_name {
  font-size: 13px;
  color: white;
  margin-top: 6px;
}

.advertisement_img_p {
  width: 100%;
  height: 225px;
}

.image_border {
  background: center #f9f9f9;
  border: 10px solid #fcfcfc;
  box-shadow: 0 0 20px #eee;
  margin-top: 30px;
  border-radius: 60px;
  padding: 35px;
  margin-bottom: 10px;
}

.image_border:hover {
  background: #ffbc00;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(9, auto);
  gap: 45px;
  position: relative;
}

.grid-box {
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.box_title_main {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.box_title {
  margin-top: 5px;
  margin-bottom: 0px;
}

.grid-box-content {
  display: flex;
  gap: 5px;
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 3px;
  padding: 3px;
  background-color: white;
  color: #060606;
  animation: slideDown 0.6s ease forwards;
  cursor: auto;
  z-index: 1;
}

.content-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  background: radial-gradient(
    ellipse at center,
    #fff 0,
    #fff 47%,
    #fafafa 60%,
    #f7f7f7 99%,
    #f7f7f7 100%
  );
  padding: 10px;
}

.right_side_subcategoy_section {
  width: 100%;
  position: relative;
}

.close-button {
  padding: 10px;
  float: inline-end;
}

.subcategory_main_div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.subcategory_para {
  background-color: #f9f9f9;
  padding: 10px 12px;
  margin-bottom: 0px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subcategory_para:hover {
  cursor: pointer;
  background-color: #e5e4e4;
}

.content-text:hover {
  cursor: pointer;
  background: #e9e8e8;
}

@keyframes slideDown {
  0% {
    top: 20px;
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    top: 80px;
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 1500px) {
  .category_content {
    gap: 25px;
  }
}

@media screen and (max-width: 1380px) {
  .grid-container {
    gap: 30px;
  }
}

@media screen and (max-width: 1250px) {
  .category_content {
    flex-direction: column;
    align-items: center;
  }
  .category_grid {
    margin: auto;
    width: 100%;
  }
  .category_content {
    gap: 50px;
  }
  .category_section {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 991px) {
  .grid-container {
    grid-template-columns: repeat(6, 1fr);
    gap: 40px;
    margin: auto;
  }
  .product_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 820px) {
  .product_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    background-color: gray;
    gap: 0px;
    width: 100%;
  }
  .box_title {
    margin-top: 0px;
  }
  .grid-box {
    display: flex;
    gap: 20px;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    margin: 5px;
  }
  .image_border {
    display: none;
  }
  .category_box_div {
    border-radius: 0px;
    padding: 0px;
    margin: 5px;
  }
}

@media screen and (max-width: 500px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    margin: auto;
  }
  .image_border {
    border: 10px solid #fcfcfc;
    margin-top: 0px;
    border-radius: 0px;
    padding: 0px;
    margin-bottom: 0px;
  }
  .box_title_main {
    margin-bottom: 0px;
  }
  .grid-box-content {
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999 !important;
  }
  @keyframes slideDown {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .content-text {
    display: block;
    width: 100%;
  }
  .subcategory_main_div {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-box {
    gap: 0px;
  }
}

@media screen and (max-width: 450px) {
  .product_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .card_main {
    width: 300px;
    margin: auto;
  }
  .category_box_div {
    padding: 5px;
    width: 35px;
  }
  .grid-box {
    gap: 7px;
  }
  .box_title {
    font-size: 12px;
    text-align: left;
  }
  .grid-container {
    gap: 0px;
  }
}