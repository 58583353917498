.header_main {
  position: fixed;
  width: 100%;
  z-index: 5;
}

body.modal-open {
  overflow: hidden;
}

.register_toast_msg {
  height: 55px;
}

.register_msg {
  font-size: 16px;
  color: #fff;
}

.register_main {
  background-color: #fff;
  width: 100%;
  padding: 50px 0px;
}

.register_info {
  display: flex;
  gap: 20px;
}

.register_form {
  width: 75%;
  padding: 30px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.main_heading_div {
  color: #fff;
  text-align: center;
  padding-top: 155px;
  font-size: 35px;
  font-weight: bold;
}

.register_heading_div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.register_heading {
  padding: 5px 0 30px;
  font-size: 30px;
  margin-left: 1%;
  margin-bottom: 30px;
}

.register_div {
  width: 100%;
  display: flex;
  gap: 40px;
}

.register_left_portion,
.register_right_portion {
  width: 50%;
}

.register_radio {
  display: flex;
  gap: 8px;
}

.register_label {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #444;
}

.company_name {
  display: flex;
  margin-top: 20px;
}

.register_input {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.astrick {
  color: red;
}

.password_input {
  display: flex;
  position: relative;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 5px;
}

.register_newsletter {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: #444;
  margin-top: 40px;
  cursor: pointer;
}

.newsletter_word {
  margin-left: 10px;
}

.terms {
  color: #bbb;
  font-size: 11px;
  margin-top: 15px;
}

.term_word {
  color: #888;
  cursor: pointer;
}

.submit_register {
  display: flex;
  justify-content: end;
}

.create_account_btn {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #ffbc00;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 15px;
}

.create_account_btn:hover {
  background-color: #d9242c;
  color: #fff;
}

.register_suggestion {
  width: 25%;
}

.registration_div {
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.register_heading_info {
  font-size: 30px;
}

.info_register {
  text-align: center;
}

.modal_register {
  z-index: 999999999999 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal_heading {
  font-weight: 700;
}

.modal_text_div {
  display: flex;
  gap: 10px;
}

.register_link {
  color: #000000;
  text-decoration: none;
}

.password_tips {
  width: 70%;
  border: 1px solid #D5CEC8;
  margin-top: 10px;
  padding: 5px;
}

.invalid {
  list-style: none;
  font-size: 14px;
}

.valid {
  color: green;
  font-weight: 500;
  font-size: 14px;
  list-style: none;
}

#passwordDescription {
  margin-bottom: 0px;
}

.password-strength-container {
  width: 100%;
  height: 5px;
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
}

.password-strength-range {
  height: 100%;
  width: 0;
  transition: width 0.3s ease-in-out;
}

.strength-1 {
  width: 7%;
  background-color: #FF4545;
}

.strength-2, .strength-3 {
  width: 20%;
  background-color: #FFC824;
}

.strength-4 {
  width: 40%;
  background-color: #008000;
}

@media screen and (max-width: 1249px) {
  .header_main {
    position: absolute;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .terms {
    display: contents;
  }
}

@media screen and (max-width: 991px) {
  .register_info {
    display: block;
  }
  .register_form {
    width: auto;
    margin-bottom: 35px;
  }
  .register_suggestion {
    width: auto;
  }
  .register_heading {
    font-size: 20px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .register_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
  .register_left_portion,
  .register_right_portion {
    width: 100%;
  }
  .register_right_portion {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .modal_register {
    width: 80%;
  }
}
@media screen and (max-width: 520px) {
  .modal_register {
    width: 95%;
  }
  .modal_heading {
    font-weight: 700;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 428px) {
  .create_account_btn {
    padding: 10px;
  }
  .register_heading {
    font-size: 20px;
  }
  .password_tips {
    width: 100%;
  }
}

@media screen and (max-width: 393px) {
  .register_heading_div {
    gap: 5px;
    font-size: 20px;
  }
}
