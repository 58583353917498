.bus_main_div {
  background-color: #f5f5f5;
}

.business_info_div {
  padding-top: 180px;
  padding-bottom: 50px;
}

.bus_dir_heading {
  color: #363636;
  font-weight: 400;
}

.list_div {
  display: flex;
  gap: 10px;
}

.list_img {
  width: 25px;
  height: 20px;
}

.bus_list_small {
  padding-left: 0px;
}

.bus_list_small li,
.bus_list_large li {
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}

.bus_list_div {
  display: flex;
}

.bus_image {
  width: 100%;
  /* height: 480px; */
}

.bus_dir_info_div {
  display: flex;
}

.bus_get_in_touch_btn {
  /* font-family: Montserrat, Arial, sans-serif; */
  width: 20%;
  margin-top: 30px;
  margin-right: 20px;
  height: 90px;
  background-color: #ffbc00;
  border: none;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.bus_dir_info {
  background-color: #fbfbfb;
  /* font-family: Montserrat, Arial, sans-serif; */
  color: #444;
  font-size: 19px;
  height: 90px;
  margin-top: 30px;
  padding: 19px;
  width: 80%;
  border-radius: 3px;
}

@media screen and (max-width: 1225px) {
  .bus_dir_info {
    font-size: 17px;
  }
  .bus_list_large,
  .bus_list_small {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .bus_list_div {
    display: block;
  }
  .bus_dir_info {
    height: 200px;
    width: 100%;
  }
  .bus_list_large,
  .bus_list_small {
    width: 100%;
  }
  .bus_list_small {
    margin-bottom: 0px;
  }
  .bus_get_in_touch_btn {
    width: 50%;
    height: 65px;
  }
  .bus_dir_info_div {
    display: block;
  }
  .bus_list_large {
    padding-left: 0px;
  }
}
