.forgot_pswd_main {
  background-color: #fff;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.forgot_pswd_form {
  width: 70%;
  margin: auto;
  padding: 30px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.forgotpswd_heading {
  font-weight: 700;
  color: #c2c2c2;
  border-bottom: 1px solid #ccc;
  text-align: center;
  padding: 5px 0 30px;
  font-size: 30px;
  margin-left: 1%;
  width: 98%;
  margin-bottom: 30px;
}

.forgotpswd_label {
  text-align: center;
  padding: 10px 0 10px 0;
  font-size: 15px;
  width: 100%;
  margin-top: 0;
  font-family: Montserrat, Arial, sans-serif;
  color: #333;
}

.forgotpswd_div {
  width: 80%;
  height: 220px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.forgotpswd_input {
  border: 1px solid #ddd;
  height: 55px;
  width: 100%;
  float: left;
  border-radius: 3px;
  padding-left: 10px;
}

.reset_btn_div {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.reset_button {
  background-color: #ffbc00;
  color: #000;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  padding: 15px;
}

@media screen and (max-width: 500px){
  .forgot_pswd_form {
    width: 100%;
    padding: 15px;
  }
}

@media screen and (max-width: 360px){
  .forgotpswd_label {
  font-size: 11px;
}
}