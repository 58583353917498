.contact_toast_msg {
  height: 50px;
}

.contact_form {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  margin: 50px 0px;
}

.contact_title {
  font-size: 18px;
  font-weight: 700;
  color: #3c4559;
  border-bottom: 1px solid rgba(210, 210, 210, 0.4);
  position: relative;
  padding: 10px 0;
}

.contact_main_div {
  position: relative;
}

.user_icon {
  position: absolute;
  top: 16px;
  left: 25px;
  font-size: 19px;
  color: #6d7588;
}

.contact_input {
  width: 100%;
  height: 50px;
  padding: 0 25px;
  padding-left: 40px;
  border-radius: 5px;
  border: 1px solid #bcc1ce;
}

.msg_input {
  height: 175px;
  padding-top: 10px;
}

.send_message {
  font-weight: 500;
  text-align: center;
  border: 0;
  padding: 0 25px;
  font-size: 16px;
  height: 50px;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  background-color: #fcbb42;
}

.contact_info {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.contact_info_icon {
  font-size: 20px;
  color: #d9242c;
  padding-top: 4px;
}

.contact_info_para {
  font-size: 17px;
  font-weight: 400;
  color: #6d7588;
}

.get_in_touch_div {
  text-decoration: none;
  color: #6d7588;
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.contact_toast_message_div {
  width: 100%;
  margin-top: 10px;
  margin-bottom: -30px;
}

.contact_info_div {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1570px) {
  .contact_info {
    padding: 10px;
  }
}

@media screen and (max-width: 991px){
  .contact_toast_msg {
    height: 0px;
  }
  .contact_info {
    margin-bottom: 50px;
  }
}