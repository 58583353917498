.save_search_div {
  width: 80%;
  margin: auto;
  min-height: 29vh;
  padding: 50px 0px;
}

.no_search_history_found {
  min-height: 35vh;
  margin-top: 20px;
}

.search_table_div {
  width: 100%;
}

.search_data {
  width: 80%;
  padding: 20px 20px 20px 0px;
}

.search_data > p {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.search_main_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 0px 10px;
}

.search_btn {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.search_item {
  margin-right: 30px;
}

.search_label {
  font-weight: 700;
}

@media screen and (max-width: 1100px) {
  .save_search_div {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .search_main_div {
    flex-direction: column;
    justify-content: left;
    padding: 10px 0px 20px;
  }
  .search_data {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (max-width: 410px) {
  .login_btn {
    padding: 7px 20px;
  }
}
