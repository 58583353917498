.category_card_list {
  display: flex;
  border-radius: 10px;
  margin: auto;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}

.category_card_list_img {
  object-fit: cover;
  width: 290px;
  height: 227px;
  border-top-left-radius: 10px;
  transition: all 0.4s ease-out 0s;
}

.category_card_list:hover .category_card_list_img {
  transform: scale(1.1);
}

.category_card_list_detail {
  background-color: #fff;
  padding: 10px 20px 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
}

.category_user_list_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.product_listing {
  min-width: 290px;
}

@media screen and (max-width: 550px) {
  .category_card_list {
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  .category_card_list_img {
    border-top-right-radius: 10px;
  }
  .category_card_list_detail {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    width: 286px;
  }
  .product_listing {
    min-height: 210px;
}
}