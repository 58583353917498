.section-padding {
  padding-top: 150px;
  background: #f5f5f5;
}

.box {
  margin-bottom: 30px;
  padding: 30px 25px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid rgba(124, 134, 154, 0.25);
  box-shadow: 0px 3px 35px rgba(218, 222, 228, 0.3);
}

.ads-details-info h2 {
  font-size: 30px;
  font-weight: 500;
}

.details-meta {
  display: flex;
  flex-wrap: wrap;
}

.details-meta span {
  color: #999;
  margin-right: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.title-small {
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
}

.card-head {
  padding: 20px;
  display: flex;
  align-items: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: #605e7e;
  background: #fff;
  display: block;
}

.detail_main_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.detail_div {
  margin-bottom: 4px;
}

.details-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.font-weight-bold {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.value_specification {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
}

.social_media_share_report_div {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.facebook,
.twitter,
.google {
  display: inline-block;
  color: #fff;
  margin-right: 5px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.linkedin {
  display: inline-block;
  color: #fff;
  margin-right: 5px;
  border-radius: 4px;
  width: 34px;
  height: 34px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.block-cell {
  display: flex;
  padding: 15px;
  width: 100%;
  border: solid 1px #ddd;
  align-items: center;
}

.cell-media {
  width: 60px;
}

.cell-media img {
  width: 100%;
  border-radius: 6px;
}

.card-user-info {
  padding: 0;
  border-radius: 0;
  border: 0;
  margin-top: 1px;
}

.cell-content {
  padding-left: 20px;
}

.title_post {
  text-transform: capitalize;
  margin: 0 0 5px;
  color: #999;
  font-size: 13px;
  padding: 0;
  line-height: normal;
}

.grid-column {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.col_from {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.ad_type_span {
  text-transform: capitalize;
}

.ev-action {
  border-top: 1px solid #ddd;
  display: grid;
  padding: 20px 0px;
}

.emailBlock {
  width: 80%;
  margin: 8px auto;
  background: #fcbb42;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.emailBlock:hover {
  background: #d9242c;
  color: #fff;
}

.location_map_div {
  border: 1px solid #ddd;
  margin-top: 20px;
}

.location_map {
  background: #fff;
  padding: 10px;
  color: #999;
  font-size: 13px;
  font-weight: 700;
}

.tabs {
  justify-content: flex-start;
}

.login_register_div {
  padding: 20px 0px;
}

.signup_btn {
  border: none;
  border-radius: 5px;
  padding: 7px 25px;
}

.login_signup_div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.question_label_div {
  padding: 20px 0px 0px 20px;
  display: grid;
  gap: 10px;
}

.ques_label {
  font-size: 13px;
  color: #979797;
}

.send_btn {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  background-color: #ffbc00;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 7px 25px;
  margin: 20px 0px 0px 20px;
}

.banner_div {
  margin: 20px auto 30px;
}

.banner_img_car {
  width: -webkit-fill-available;
  cursor: pointer;
}

.button_div {
  display: flex;
  gap: 20px;
  justify-content: end;
  font-weight: 500;
}

.button_div span {
  cursor: pointer;
}

.button_div span:hover {
  color: #fcbb42;
}

.q_ad_a_div {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 30px;
  margin: 20px;
  padding: 10px 20px;
  border: solid 1px #ddd;
}

.q_ad_a_div > div p {
  margin: 0px;
  padding: 0px;
}

.user_name_font {
  font-weight: 600;
}

.svg_message {
  font-size: 30px;
}

.q_and_a_div_scroll {
  width: 70%;
  max-height: 300px;
  overflow: auto;
}

.q_and_a_no_data {
  padding: 20px 0px;
  text-align: center;
  color: lightgrey;
  font-size: 18px;
  font-weight: 500;
}

.print_btn_div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.print_btn {
  font-weight: 500;
  cursor: pointer;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

.business_user_name {
  cursor: pointer;
}

.buy_now {
  font-size: 20px;
}

.product_load_img_div {
  display: flex;
  justify-content: center;
}

.product_loader_img {
  margin: 300px;
  height: 70px;
}

.related_product_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 20px;
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .share {
    margin-bottom: 10px;
  }
  .tabs {
    background-color: #fff;
  }
  .emailBlock,
  .phoneBlock {
    width: 30%;
  }
  .banner_div {
    display: flex;
  }
  .banner_img_car {
    margin: auto;
    width: auto;
  }
  .section-padding {
    padding-top: 180px;
  }
  .related_product_grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .details-box {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 820px) {
  .related_product_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 710px) {
  .detail_main_div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 553px) {
  .emailBlock,
  .phoneBlock {
    width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .related_product_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 420px) {
  .detail_main_div {
    grid-template-columns: repeat(1, 1fr);
  }
  .q_ad_a_div > div p {
    font-size: 14px;
  }
  .user_name_font {
    font-weight: bold;
  }
  .svg_message {
    font-size: 40px;
  }
}

@media print {
  @page {
    size: A3;
    margin: 1cm;
  }
  .no-page-break {
    page-break-inside: avoid;
    page-break-before: avoid;
  }
  .navbar {
    display: none;
  }
  body {
    font-size: 15pt;
  }
  .box_mar {
    margin-top: 100px;
  }
  .location_mar {
    margin-top: 100px;
  }
  .banner_img_car {
    margin-top: 100px;
    width: 600px;
    height: 600px;
  }
  .featured_heading {
    margin-top: 50px;
  }
  .footer_main {
    margin-top: 150px;
  }
}
