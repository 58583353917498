.footer_main {
  background-color: #222;
}

.footer_title {
  color: #fcbb42;
  padding-top: 30px;
  margin-bottom: 20px;
}

.footer_title a {
  text-decoration: none;
}

.footer_section_top {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
  flex-wrap: wrap;
}

.footer_mail {
  text-decoration: none;
  color: #fff;
}

.footer_mail:hover {
  color: #fcbb42;
  text-decoration: underline;
}

.need_help {
  display: flex;
  color: #fff;
  gap: 7px;
}

.need_help > a {
  text-decoration: none;
  color: #fff;
}

.social {
  display: flex;
  gap: 30px;
}

.social_media_footer {
  display: flex;
  gap: 30px;
  margin: 20px auto;
  justify-content: center;
}

.social_media_img {
  width: 35px;
  height: 35px;
}

.link_dealer_tag {
  display: flex;
  color: #fff;
  margin-bottom: 6px;
}

.link_dealer {
  text-decoration: none;
  color: #fff;
  font-family: Helvetica, San-Serif, sans-serif;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.link_dealer:hover {
  color: #fcbb42;
  text-decoration: underline;
}

.feedback_btn {
  width: 100%;
  margin-top: 20px;
  background-color: #d9242c;
  color: #fff;
  border: #d9242c;
}

.feedback_btn:hover {
  background-color: #fcbb42;
  color: #000;
  border: #fcbb42;
}

.register_footer {
  background-color: #d9242c;
  color: #fff;
  border: #d9242c;
}

.register_footer:hover {
  background-color: #fcbb42;
  color: #000;
  border: #fcbb42;
}

.copyright {
  color: #fff;
  background-color: #393939;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.footer_arrow {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.hover_link {
  display: flex;
  color: #fff;
  margin-bottom: 6px;
}

.hover_link:hover {
  color: #fcbb42;
}

.success-message {
  color: #008000;
  margin: 10px 0px 0px 0px;
  width: 270px;
}

.footer_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upside_arrow_icon {
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  color: #06060f;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}

.copyright a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 1127px) {
  .footer_section_top {
    justify-content: space-around;
  }
}

@media screen and (max-width: 666px) {
  .footer_section_top {
    display: block;
  }
}

@media screen and (max-width: 460px) {
  .footer_section_top {
    justify-content: space-between;
  }
  .footer_div {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 330px) {
  .social_media_footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}