.optionnavbar_main {
  background: #4444448c;
  width: 100%;
  height: 55px;
}

.optionnavabar_btn_div {
  width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optionnavbar_btn,
.optionnavbar_btn_browse {
  width: 33.33%;
  height: 54px;
  border-radius: 0;
  font-size: 18px;
  font-family: Montserrat, Arial, sans-serif;
  text-align: center;
}

.optionnavbar_btn {
  background-color: #d9242c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.optionnavbar_btn_browse {
  background-color: #f4f4f4;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.optionnavbar_btn:hover {
  background-color: #d9242c;
}

.optionnavbar_btn_browse:hover {
  background-color: #f4f4f4;
  color: #5b5a5a;
}

.place_ad_div_section {
  width: 100%;
  margin: auto;
  padding: 20px;
  background-color: #5b5a5a;
  border-radius: 3px;
  box-sizing: border-box;
}

@media screen and (max-width: 1449px) {
  .optionnavbar_btn,
  .optionnavbar_btn_browse {
    font-size: 14px;
  }
}

@media screen and (max-width: 1165px) {
  .optionnavabar_btn_div {
    width: 70%;
  }
  .place_ad_div_section {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .optionnavabar_btn_div {
    width: 100%;
  }
}

@media screen and (max-width: 541px) {
  .optionnavbar_btn_browse, .optionnavbar_btn {
    gap: 5px;
  }
}

@media screen and (max-width: 582px) {
  .optionnavbar_btn,
  .optionnavbar_btn_browse {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .place_ad_div_section {
    width: 100%;
    padding: 0px;
  }
}
