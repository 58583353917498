.dashboard_main {
  min-height: 44vh;
}

.dashboard_heading {
  text-align: center;
  margin-top: 10px;
  font-size: 40px;
  font-weight: 500;
}

.dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.dashboard_box {
  cursor: pointer;
  width: 200px;
  height: 200px;
  margin: 20px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dadada;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.dashboard_box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.dashboard_icon {
  padding: 30px;
  height: 120px;
}

.dashboard_rectangle {
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
}

.dashboard_btn_label {
  margin: 0;
  padding: 5px;
}

.balance_check_div {
  margin-top: 50px;
}

.place_ad_balance_div {
  margin: 50px 0px;
}

.balance_heading {
  text-align: center;
  padding: 5px 0 30px;
  font-size: 30px;
}

.join_business_dir {
  border: 1px solid black;
  padding: 20px;
  margin: 50px 0px;
}


@media screen and (max-width:450px) {
  .dashboard_heading {
    font-size: 25px;
  }
}