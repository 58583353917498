.report_main {
  background-color: #fff;
  width: 100%;
  margin: 50px 0px;
}

.report_form {
  width: 50%;
  padding: 30px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.report_suggestion {
  width: 50%;
}

.fullView {
  color: #d9242c;
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
}

.full_view_span:hover {
  cursor: pointer;
  color: #ffbc00;
}

@media screen and (max-width: 991px) {
  .report_form {
    width: 100%;
  }
  .report_suggestion {
    display: none;
  }
}
