.login_main {
  background-color: #fff;
  width: 100%;
  padding: 50px 0px;
}

.login_form {
  width: 50%;
  margin: auto;
  padding: 30px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.login_heading {
  text-align: center;
  padding: 5px 0 30px;
  font-size: 30px;
  border-bottom: 1px solid #f5f5f5;
  margin-left: 1%;
  width: 98%;
  margin-bottom: 30px;
}

.login_register_heading {
  margin-top: 150px;
  color: #363636;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

.login_register_para {
  font-size: 14px;
  color: #888;
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: center;
}

.forgot_pswd {
  text-align: right;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 14px;
  color: #888;
}

.login_label {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #444;
}

.link_div {
  margin-top: 10px;
}

.login_input {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.submit_login {
  margin-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.login_btn {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  background-color: #ffbc00;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 7px 25px;
}

.login_btn:hover {
  background-color: #d9242c;
  color: #fff;
}

.forget_username {
  color: #d9242c;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-bottom: 0
}

.login_problem {
  font-weight: 600;
  margin-top: 10px;
}

.cookies {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 14px;
  color: #888;
}
.delete_login{
  display: flex;
  justify-content: center;
  border-bottom: 0px;
}
.delete_form{
  width: 40%;
}
@media screen and (max-width:1250px) {
  .delete_form {
    width: 50%;
  }
}
@media screen and (max-width: 991px) {
  .login_form {
    width: 100%;
  }
  .delete_form {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .login_heading {
    display: flex;
    gap: 75px;
  }
  .login_register_form {
    display: none;
  }
  .delete_form {
    width: 100%;
  }
}
