.business_body {
  padding-top: 105px;
  display: flex;
}

.business_body_search_section {
  width: 70%;
}

.search_section {
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 30px 20px;
  background-color: #ccc;
  margin-bottom: 20px;
}

.big_search_div_section {
  width: 60%;
  display: flex;
}

.small_search_div_section {
  width: 35%;
  display: flex;
  gap: 10px;
}

.input_box_big {
  border: none;
  border-radius: 3px;
  width: 100%;
  outline: none;
  padding: 0px 5px;
}

.input_box_small {
  width: 70%;
  border: none;
  border-radius: 3px;
  padding: 5px 0px;
  outline: none;
  padding: 0px 5px;
}

.business_body_right_section {
  width: 30%;
  border-left: 1px solid #ddd;
}

.result_section {
  width: 100%;
  height: 117px;
  border-bottom: 1px solid #ddd;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.location_svg {
  color: #d9242c;
}

.listing_company {
  height: 650px;
  overflow-y: scroll;
}

.map_section {
  width: 100%;
  height: 750px;
  position: relative;
}

.listing_company ul {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #fff;
}

.listing_company ul > a {
  text-decoration: none;
  color: #333;
}

.listing_company li {
  list-style-type: none;
  font-size: 16px;
  white-space: nowrap;
  border-bottom: 1px solid #eaeaea;
  padding: 14px 10px 14px 10px;
  cursor: pointer;
}

.drop_down_big_search {
  min-height: 50px;
  max-height: 450px;
  position: absolute;
  top: 180px;
  z-index: 1;
  background-color: white;
}

.drop_down_big_search ul {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #fff;
}

.recommendations > a {
  text-decoration: none;
  color: #333;
}

.no_data_found_search {
  margin: 0px !important;
  padding: 10px;
}

.drop_down_big_search li {
  list-style-type: none;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  padding: 14px 10px;
  cursor: pointer;
}

.recommendations li:nth-child(even) {
  background-color: #efefef;
}

.drop_down_big_search > ul.recommendations > a:nth-child(even) li {
  background-color: #efefef;
}

.drop_down_big_search > ul.recommendations > a:nth-child(even) li:hover {
  background-color: #333;
  color: #fff;
}

.drop_down_big_search li:hover {
  background-color: #333;
  color: #fff;
}

.marker-info-win p {
  padding: 0px;
  margin: 10px 0px 10px 0;
}

.marker-inner-win {
  padding: 5px;
}

.marker-info-win {
  line-height: 1.35;
  overflow: hidden;
  white-space: nowrap;
}

.marker-heading :focus-visible {
  outline: none;
}

.marker-heading a {
  color: #e2141c;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 15px;
  text-decoration: none;
}

.gm-style-iw-t {
  right: 0px !important;
  bottom: 59px !important;
}

.info_mail_link {
  color: #e2141c;
  text-decoration: none;
}

.map_section > a {
  text-decoration: none;
}

.join-directory {
  float: right;
  position: absolute;
  bottom: 60px;
  z-index: 10000;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  color: #333;
  right: auto;
  margin-left: 10px;
}

.greater_than {
  color: #222;
  font-weight: bolder;
}

.span_data:hover {
  color: #e2141c;
  cursor: pointer;
}

.selected {
  background-color: #f0f0f0;
}

@media screen and (max-width: 1230px) {
  .business_body {
    padding-top: 105px;
  }
  .search_section {
    flex-direction: column;
  }
  .drop_down_big_search {
    width: 50%;
    top: 175px;
  }
  .drop_down_small_search {
    width: 50%;
    top: 240px;
  }
  .big_search_div_section,
  .small_search_div_section {
    width: 100%;
  }
  .input_box_big {
    padding: 6px;
  }
  .join-directory {
    bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .business_body {
    padding-top: 160px;
  }
  .drop_down_big_search {
    width: 60%;
    top: 235px;
  }
  .drop_down_small_search {
    width: 60%;
    top: 292px;
  }
}

@media screen and (max-width: 767px) {
  .business_body {
    padding-top: 160px;
    flex-direction: column;
  }
  .map_search_btn {
    text-align: center;
  }
  .business_body_search_section {
    width: 100%;
    height: auto;
  }
  .business_body_right_section {
    width: 100%;
  }
  .join-directory {
    bottom: 10px;
  }
  .small_search_div_section {
    flex-direction: column;
  }
  .drop_down_big_search {
    width: 80%;
  }
  .drop_down_small_search {
    width: 80%;
  }
  .map_section {
    display: none;
  }
}
