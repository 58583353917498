.card_main {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.product_link {
  color: #000000;
  text-decoration: none;
}

.card_img {
  object-fit: cover;
  width: 100%;
  height: 210px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.4s ease-out 0s;
}

.card_main:hover .card_img {
  transform: scale(1.1);
}

.card_detail {
  background-color: #fff;
  padding: 10px 20px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.user_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.user_detail {
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
}

.wishlist {
  font-size: 20px;
}

.product_detail {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0px;
}

.product_line_divide {
  width: 30%;
  color: red;
}

.product_line {
  width: 70%;
  color: lightgrey;
}

.product_name_div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.product_name {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.location_div {
  font-size: 16px;
  font-weight: 500;
  color: #6d7588;
  height: 55px;
}

.location_detail {
  display: flex;
  gap: 5px;
}

.product_price {
  font-size: 20px;
  font-weight: 700;
  color: #d9242c;
  margin-top: 5px;
}

.date {
  margin-top: 10px;
  font-size: 16px;
  color: #bcb7b7;
}

.product_img {
  min-height: 210px;
}