.verify_form {
  padding: 30px;
  margin: 60px auto;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
}

.verify_form_div {
  text-align: center;
}
