.status_update_div {
  text-align: center;
  margin: 50px 0px;
  min-height: 18vh;
}

.status_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
