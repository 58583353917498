.carousel_card1 {
  height: 180px;
}

.category_page_main {
  background-color: #f5f5f5;
  padding-top: 185px;
  padding-bottom: 20px;
}

.home_breadcrumb a {
  color: black;
  text-decoration: none;
  text-transform: uppercase;
}

.home_breadcrumb a:hover {
  color: #555;
}

.breadcrumb .active a {
  color: #ff4367;
  text-decoration: none;
  text-transform: uppercase;
}

.breadcrumb .active a:hover {
  color: #555;
}

.sidebar_categories {
  background-color: #fff;
  border-radius: 10px;
}

.accordion_space {
  margin-top: 10px;
}

.accordion_body {
  max-height: 250px;
  overflow-y: scroll;
}

.toast_message_div {
  width: 100%;
  margin-bottom: 10px;
}

.filter_btn {
  border-radius: 5px;
  background-color: #ccc;
  border: 1px solid #ccc;
  color: #000;
  padding: 6px 12px;
}

.save_search_btn {
  border-radius: 5px;
  border: none;
  background-color: #fcbb42;
  color: #000;
  padding: 6px 12px;
}

.save_search_btn:hover {
  background-color: #d9242c;
  color: #fff;
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.sidebar_categories_content {
  padding: 15px 0;
}

.ads_total_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.product_dropdown_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.product_dropdown {
  display: flex;
  align-items: center;
  gap: 15px;
}

.card {
  padding: 0;
  border-radius: 0;
  border: 0;
  margin-top: 15px;
}

.category_name_main {
  font-size: 14px;
  font-weight: 400;
  color: #6d7588;
}

.category_icon_main {
  margin-right: 5px;
}

.grid_icon {
  font-size: 30px;
  cursor: pointer;
  color: #505050;
}

.list_icon {
  font-size: 40px;
  cursor: pointer;
  color: #505050;
}

.grid {
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  min-height: 40vh;
}

.list {
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  gap: 20px;
}

.categories-container {
  display: flex;
  flex-direction: column;
}

.category {
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category p {
  margin: 0;
}

.subcategories {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
}

.subcategories li {
  padding: 8px;
  color: #6d7588;
}

.subcategories li:hover {
  color: #ff4367;
}

.account_title {
  cursor: pointer;
  text-transform: capitalize;
}

.selected_item_div {
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.selected_heading {
  font-weight: 700;
  color: #000;
  font-size: 18px;
}

.selected_item_category {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-left: 10px;
}

.selected_item {
  font-weight: 700;
  color: #000;
  font-size: 15px;
}

.selected_div {
  text-transform: capitalize;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  gap: 9px;
}

.color {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.color_div {
  width: 100%;
  height: 35px;
  border-radius: 3px;
}

.models-container {
  border: 1px solid #dee2e6;
  background-color: white;
  margin-top: 10px;
  max-height: 250px;
  overflow-y: scroll;
}

.title_model {
  font-size: 18px;
  color: #000000;
  padding: 18px;
}

.category_model {
  padding-left: 18px;
  margin: 10px;
  cursor: pointer;
}

.feature_img {
  width: 100%;
}

.cancel_btn {
  border: 1px solid #ccc;
}

.dropdown_page {
  background: #ccc;
  border-radius: 5px;
  color: #000;
}

.dropdown_page_main {
  background: #ccc;
  border: 1px solid #ccc;
  color: #000;
}

.dropdown_page_main:hover {
  background: #ccc !important;
  border: 1px solid #ccc;
  color: #000;
}

.dropdown_page_main:focus {
  background: #ccc !important;
  border: 1px solid #ccc !important;
  color: #000;
}

.accordion_header:active {
  background: none !important;
  outline: none !important;
}

.load_img_div {
  display: flex;
  justify-content: center;
}

.loader_img {
  height: 70px;
  margin-top: 50px;
}

.loader_category_img {
  height: 70px;
  margin: 20px 0px;
  color: white;
}

.place_ad_div_section_loader {
  width: 72%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #5b5a5a;
  border-radius: 3px;
  box-sizing: border-box;
}

.slider_label {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.preview {
  background-color: #efefef;
  font-size: 18px;
  width: 100%;
  height: 225px;
  border-radius: 3px;
  text-align: center;
  padding-top: 30px;
  border: 1px solid #e9e9e9;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.preview h1 {
  color: #363636;
  font-size: 26px;
  font-weight: 400;
  width: 100%;
  padding-bottom: 16px;
}

.preview p {
  color: #333;
  font-size: 14px;
  width: 100%;
  font-weight: 400;
}

.main_category {
  color: #428bca;
  text-decoration: none;
}

@media screen and (max-width: 1400px) {
  .color-grid {
    grid-template-columns: repeat(2, minmax(80px, 1fr));
  }
  .carousel_card1 {
    height: auto;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .offcanvas {
    width: 100%;
  }
  .aside {
    display: none;
  }
  .category_page_main {
    padding-top: 185px;
  }
}

@media screen and (max-width: 606px) {
  .product_dropdown {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 543px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .product_dropdown_grid {
    display: none;
  }
  .product_title , .product_ads {
    font-size: 18px;
  }
  .save_search_btn , .dropdown_page_main , .filter_btn {
    font-size: 13px;
  }
}

@media screen and (max-width: 365px) {
  .product_dropdown_main {
    display: block;
    margin: auto;
  }
}
