.wishlist_main_div {
  background-color: #f5f5f5;
}

.wishlist_main {
  width: 80%;
  margin: auto;
  background-color: #f5f5f5;
  padding: 50px 0px;
  min-height: 30vh;
}