.business_detail_div {
  padding-top: 150px;
  padding-bottom: 70px;
}

.back_breadcrumb a {
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}

.back_breadcrumb a:hover {
  color: #d9242c;
}

.company_location {
  width: 48%;
}

.load_business {
  min-height: 28vh;
}

.company_detail_div {
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.company_detail {
  width: 50%;
}

.business_company_div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.detail_span {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0px;
}

.business_icon_div {
  width: 30%;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
}

.company_detail_icon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.business_icon {
  width: 20px;
  height: 20px;
}

.social_media_link_business {
  display: flex;
  gap: 10px;
}

.mail_info {
  color: #d9242c;
  text-decoration: none;
}

.company_search_div {
  display: flex;
  align-items: center;
}

.company_search_div > div {
  width: 100%;
}

.location_input {
  /* width: 640px; */
  width: 100%;
  height: 50px;
  border: 1px solid #dbdbdb;
  border-right: none;
  padding: 10px;
  border-radius: 5px 0px 0px 5px;
}

.location_input:focus-visible {
  outline: none;
}

.location_search_icon {
  width: 30px;
  height: 30px;
  color: #ffffff;
}

.location_button {
  width: 52px;
  height: 52px;
  background: #d9242c;
  border: 1px solid #dbdbdb;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

.google_location_div {
  margin-top: 10px;
}

.marking_location {
  border: 1px solid #ececec;
}

.marking_location {
  padding: 10px;
}

.business_print {
  background: transparent;
  border: none;
  color: #d9242c;
  font-size: 18px;
  font-weight: 500;
  border-right: 1px solid #e9e9e9;
  padding-right: 24px;
}

.business_share {
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 0;
}

.share_social_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 10px;
}

.share_icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.share_pintrest_icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.modal_business {
  z-index: 999999999999 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 40%;
  height: 40%;
  background: linear-gradient(60deg, #f2c14e, #f78154);
}

.overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 25px;
}

.directory_popup_content {
  vertical-align: middle;
}

.modal_text {
  color: #d9242c;
  text-align: center;
}

.modal_link {
  color: #d9242c;
  text-decoration: underline;
}

.grid_background {
  background-color: #f5f5f5;
  padding: 20px 0px;
  margin-bottom: 40px;
}

.product_grid_map {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.adp {
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

.adp-summary {
  padding: 80px 3px 3px 3px !important;
  margin-top: 25px !important;
  margin-bottom: 10px !important;
  margin-left: 35px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", Arial, sans-serif !important;
}

.adp button {
  height: 62px !important;
  width: 100% !important;
  float: left !important;
  background-color: #eee !important;
}

.adp-placemark {
  border: none !important;
}

.adp-placemark .adp-text {
  font-family: "Montserrat", Arial, sans-serif;
  vertical-align: middle;
  padding-left: 50px;
}

.adp-placemark > tbody > tr td:nth-child(2) {
  background-image: url("../Images/marker-b.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  padding-left: 40px;
  margin-left: 20px;
}

.adp-marker2 {
  display: none !important;
}

.adp-directions {
  width: 100% !important;
}

.adp-legal {
  display: none !important;
}

.toggle_button {
  display: none;
}

@media screen and (max-width: 1300px) {
  .company_detail_div {
    display: block;
    margin: auto;
  }
  .company_detail {
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
  }
  .company_location {
    width: 100%;
  }
  .business_icon_div {
    width: auto;
  }
  .detail_span {
    gap: 20px;
  }
  .location_input {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .product_grid_map {
    grid-template-columns: repeat(3, 1fr);
  }
  .business_detail_div {
    padding-top: 200px;
}
}

@media screen and (max-width: 820px) {
  .product_grid_map {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .toggle_button {
    display: flex;
    gap: 5px;
    margin-top: 50px;
  }
  .company_location, .details_div {
    display: none;
  }
  .blockDetails, .blockMap {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .modal_business {
    width: 80%;
  }
}

@media screen and (max-width: 495px){
  .product_grid_map {
    grid-template-columns: repeat(1, 1fr);
    width: 70%;
  }
}

@media screen and (max-width: 450px) {
  .product_grid_map {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
  .modal_text {
    font-size: 18px;
  }
  .business_share, .business_print {
    font-size: 16px;
  }
  .share_icon, .share_pintrest_icon {
    width: 25px;
    height: 25px;
  }
  .company_detail {
    margin-bottom: 30px;
  }
  .adp-placemark > tbody > tr td:nth-child(2) {
    height: 30px;
    font-size: 12px;
}
}

@media screen and (max-width: 341px) {
  .business_share {
    font-size: 15px;
    margin-left: 0px;
  }
  .business_print {
    font-size: 15px;
  }
  .share_icon, .share_pintrest_icon {
    width: 25px;
    height: 25px;
  }
  .share_social_icon {
    margin-left: 0px;
  }
}

@media print {
  @page {
    size: A3;
    margin: 1cm;
  }

  .company_detail_div {
    display: block;
  }

  .company_detail {
    width: 100%;
  }

  .featured_heading {
    margin-top: 100px;
  }
}
