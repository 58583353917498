.myad_main {
  background-color: #fff;
  width: 100%;
  padding: 50px 0px;
}

.my_ad_btn {
  display: flex;
  gap: 20px;
}

.table th,
.table td {
  text-align: center;
  vertical-align: middle;
}

.table {
  border: 1px solid #dadada;
}

.row_table {
  cursor: pointer;
}

.table_main {
  margin: 20px 0px;
}

.order_list {
  list-style-type: none;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.hidden_ordered_list {
  justify-content: left;
}

.table_photo {
  height: auto;
  width: 130px;
  margin: auto;
}

.ad_image {
  width: 70px;
  height: 70px;
}

.icons_btn {
  background-color: transparent;
  border: none;
}

.icons_btn:hover {
  background-color: none;
  border: none;
}

.icon_edit1 {
  font-size: 24px;
  color: #d9242c;
}

.icon_edit1::before {
  transform: rotate(180deg);
}

.icon_edit1::after {
  transform: rotate(90deg);
}

.icon_edit {
  font-size: 24px;
  color: #d9242c;
}

.icon_edit:hover {
  color: #000000;
}

.active_btn {
  background-color: #4cae4c;
  border: 1px solid #4cae4c;
  color: #fff;
  border-radius: 5px;
}

.active_btn:hover {
  background-color: #4dc34d;
}

.active_btn_inactive {
  background-color: #d9242c;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.active_btn_inactive:hover {
  background-color: #c32028;
}

.item_day {
  font-size: 14px;
}

.toast_message {
  color: #fff;
}

.myad_error_message {
  margin-top: 20px;
  min-height: 100px;
}

.transparent-backdrop {
  background-color: rgba(0,0,0,0.2) !important;
}

@media screen and (max-width: 815px) {
  .action {
    display: none;
  }
}

@media screen and (min-width: 816px) {
  .action_res {
    display: none;
  }
}