.about_heading_title {
  margin-top: 30px;
}

.founder_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: space-around;
  text-align: center;
  gap: 20px;
  margin: 50px 0px;
}

.single_owner_grid {
  flex: 1;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 50px 0px;
  min-height: 360px;
  box-sizing: border-box;
}

.single_owner_grid:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.owner_img {
  height: 100px;
}

.para_div {
  margin-top: 30px;
  line-height: 15px;
}

.mail_color {
  color: #fcbb42;
}

.image_grid_about {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-bottom: 50px;
  margin-top: 30px;
}

.single_image_grid {
  flex: 1;
  overflow: hidden;
}

.gallary_image {
  width: 100%;
  transition: transform 0.3s ease;
}

.gallary_image:hover {
  transform: scale(1.1);
}
.mbottom{
  margin-bottom: 50px;
}
@media screen and (max-width: 1150px) {
  .founder_grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .image_grid_about {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 991px) {
  .founder_grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .image_grid_about {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 650px) {
  .founder_grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .image_grid_about {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .founder_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .image_grid_about {
    grid-template-columns: repeat(1, 1fr);
  }
}