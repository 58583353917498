.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.pagination button,
.pagination span {
  padding: 7px 12px;
  margin: 0 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: #f8f9fa;
}

.pagination button:hover,
.pagination span:hover {
  background-color: #d9242c;
  color: #fff;
}

.pagination button.next_btn {
  background-color: #fcbb42;
  color: #000;
}

.pagination .ellipsis {
  font-weight: bold;
}

.pagination .active {
  background-color: #fcbb42;
  color: #000;
}

@media screen and (max-width: 500px) {
  .pagination button,
  .pagination span {
    padding: 8px;
    margin: 5px 2px;
  }
}
