.upload_image_div {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 35px 25px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.075);
  margin-top: 50px;
  margin-bottom: 50px;
}

.upload_image_content {
  text-align: center;
}

.photo_icon {
  width: 12%;
  height: 50px;
}

.add_photo {
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #d9242c;
  color: #fff;
  border: #d9242c;
  border-radius: 5px;
}

.add_photo:hover {
  background-color: #fcbb42;
  color: #000;
  border: #fcbb42;
}

.img_tab {
  height: 125px;
}

.user_upload_img {
  max-width: 125px;
}

.rotate_btn_div {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.rotate_btn {
  width: 45px;
  height: 45px;
}

.rotate_div {
  display: flex;
  gap: 20px;
}

.prev_div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.prev_btn {
  padding: 10px 20px;
  background-color: #fcbb42;
  color: #000;
  border: #fcbb42;
  border-radius: 5px;
}

.prev_btn:hover {
  background-color: #d9242c;
  color: #fff;
  border: #d9242c;
}

@media screen and (max-width: 413px) {
  .photo_icon {
    width: 17%;
  }
}
