.navbar {
  background-color: #fcbb42;
  padding: 0px;
}

.nav_link_ad {
  background-color: transparent;
  border: none;
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding: 2px 15px;
  display: flex;
  align-items: center;
}

.nav_link_ad:hover {
  color: #d9242c;
}

.nav_link {
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding: 2px 15px;
  display: flex;
  align-items: center;
}

.nav_link:hover {
  color: #d9242c;
}

.logo {
  cursor: pointer;
}

.search_bar {
  background-color: #f8f9fa;
  border: none;
  width: 320px !important;
}

.search_bar:focus {
  border: none;
  box-shadow: none;
}

.link_line {
  height: 50px;
  border: 1px solid #f4f400;
  margin: 0 10px;
}

.nav_bar_line {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.search_input {
  margin: 10px auto;
  width: 100%;
}

.user_name {
  margin-top: 1px;
}

@media screen and (max-width: 1400px) {
  .search_bar {
    width: 250px !important;
  }
}

@media screen and (max-width: 1200px) {
  .navbar_btn {
    gap: 2px;
  }
  .nav_link, .nav_link_ad {
    font-size: 12px;
  }
  .search_bar {
    width: 150px !important;
  }
}

@media screen and (min-width: 991px) {
  .show_search {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .link_line {
    border: none;
  }
  .navbar_search {
    margin-top: 10px;
  }
  .nav_link, .nav_link_ad {
    color: white;
    font-weight: 700;
    padding: 10px 15px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }
  .reg_login_button {
    display: flex;
    gap: 20px;
    margin-top: 15px;
  }
  .navbar {
    background-color: #fcbb42;
  }
  .nav_bar_line {
    background-color: #fcbb42;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  .quick_link_div {
    width: 100%;
  }
  .quick_link_heading {
    padding: 10px;
    margin-bottom: 0px;
    font-weight: 700;
  }
  .quick_link {
    font-size: 15px;
    color: #444;
    border-top: 1px solid #eee;
    padding-top: 3px;
    padding-left: 2%;
    padding-right: 2%;
    text-indent: 42px;
  }
  .show_search {
    display: none;
  }
  .language_drop {
    margin-bottom: 10px;
  }
}
