.payment_main_div {
  padding-top: 180px;
  padding-bottom: 50px;
}

.payment {
  padding: 2% 2% 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.payment_heading {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 5px 0 30px;
  font-size: 30px;
}

.payment_text {
  text-align: center;
}

.buy_credit {
  padding: 5px 0 25px;
  font-size: 18px;
  text-align: center;
  width: 100%;
  margin-top: 60px;
}

.payment_card {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.debit_card_btn {
  border: 1px solid #ccc;
  height: 145px;
  width: 330px;
}

@media screen and (max-width: 580px) {
  .buy_credit {
    margin-top: 25px;
  }
  .debit_card_btn {
    height: 100px;
    width: 200px;
  }
  .debit_card_img {
    width: 100px;
  }
}

@media screen and (max-width: 460px) {
  .payment_main_div {
    padding-top: 200px;
  }
  .payment_heading {
    padding: 5px 0 15px;
    font-size: 20px;
  }
  .payment_text {
    font-size: 15px;
  }
  .debit_card_btn {
    height: 80px;
  }
}