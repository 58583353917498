.credit_box {
  padding: 5px 0 25px;
  font-size: 18px;
  text-align: center;
  width: 100%;
  margin-top: 60px;
}

.credit_box input {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: none !important;
  border-radius: 3px;
  color: #ccc;
  width: 104px;
  font-size: 50px;
  font-weight: 400;
  text-align: right !important;
}

.credit_box input:focus {
  color: #000 !important;
  outline: none;
}

.credit_box label,
.zero {
  font-size: 50px;
  font-weight: 400;
}

.buy_credit_btn {
  background-color: #ffbc00;
  color: #000;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  border: none;
  width: 220px;
  font-weight: 600;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
}

.buy_credit_btn:hover {
  background-color: #d9242c;
  color: #fff;
}

.paypal_main_div_button {
  width: 40%;
  margin: 20px auto;
}

@media screen and (max-width: 460px) {
  .credit_box {
    margin-top: 15px;
  }
  .credit_box input {
    width: 80px;
    font-size: 30px;
  }
  .buy_credit_btn {
    width: 160px;
    height: 55px;
  }
  .payment {
    padding: 2% 2% 5%;
  }
  .credit_box label,
  .zero {
    font-size: 30px;
  }
  .paypal_main_div_button {
    width: 70%;
  }
}