.box_shadow_cookies {
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.cookies_icon_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookies_icon {
  font-size: 250px;
}

.cookies_information {
  padding: 15px;
}

.link_cookies {
  color: #d9242c;
  font-weight: 500;
}

.link_cookies:hover {
  color: #fcbb42;
}

.unorder_cookies {
  list-style-type: none;
  padding-left: 0px;
}
